// Generated by Xata Codegen 0.30.1. Please do not edit.
import { buildClient } from "@xata.io/client";
/** @typedef { import('./types').SchemaTables } SchemaTables */
/** @type { SchemaTables } */
const tables = [
  {
    name: "lessons",
    columns: [
      { name: "title", type: "string" },
      { name: "videoId", type: "string" },
      { name: "exercises", type: "json" },
      { name: "description", type: "string" },
      { name: "slidersLink", type: "string" },
      { name: "level", type: "string" },
      { name: "isArchived", type: "bool", defaultValue: "false" },
      { name: "orderNumber", type: "int" },
    ],
  },
  {
    name: "users",
    columns: [
      { name: "email", type: "email", unique: true },
      { name: "password", type: "string" },
      { name: "isAdmin", type: "bool", defaultValue: "false" },
      { name: "name", type: "string" },
      { name: "avatarSrc", type: "string" },
    ],
  },
  {
    name: "exercises",
    columns: [
      { name: "status", type: "string", defaultValue: "new" },
      { name: "correctAnswers", type: "multiple" },
      { name: "userId", type: "string" },
      { name: "lessonId", type: "string" },
    ],
  },
];
/** @type { import('@xata.io/client').ClientConstructor<{}> } */
const DatabaseClient = buildClient();
const defaultOptions = {
  enableBrowser: true,
  apiKey: process.env.REACT_APP_XATA_API_KEY,
  branch: process.env.REACT_APP_XATA_BRANCH,
  fetch: fetch,
  databaseURL:
    "https://Netanel-Ben-s-workspace-cu8f70.eu-west-1.xata.sh/db/david-english",
};
/** @typedef { import('./types').DatabaseSchema } DatabaseSchema */
/** @extends DatabaseClient<DatabaseSchema> */
export class XataClient extends DatabaseClient {
  constructor(options) {
    super({ ...defaultOptions, ...options }, tables);
  }
}
let instance = undefined;
/** @type { () => XataClient } */
export const getXataClient = () => {
  if (instance) return instance;
  instance = new XataClient();
  return instance;
};
