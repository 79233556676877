import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { slugify } from "../../utils";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import CourseInfo from "../../components/course/CourseInfo";
import CourseData from "../../data/course/CourseData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";
import { useGetLessonById } from "../../services/xataDbLessonById";
import {
  getExerciseById,
  persistExerciseCompleted,
  startExerciseAPI,
} from "../../services/xataDbLessonExercise";
import { Lesson } from "../../types/Lesson";
import { ExerciseItem } from "../../LessonExercise/ExerciseItem";
import { persistCorrectAnswer } from "../../services/xataDbCorrectAnswer";
import "./lessonPage.scss";
import { useAuth } from "../../hooks/auth";

export const LessonAndExercisePage = () => {
  const { id } = useParams();
  if (!id) return null;
  return <LessonAndExercise id={id} />;
};

const LessonAndExercise = (props: { id: any }) => {
  const { user } = useAuth();
  const userId = user.id;

  const lesson: Lesson = useGetLessonById({ id: props.id }).lesson;
  const lessonId = lesson.lessonId;
  const slidersLink = lesson.slidersLink;
  const lessonTitle = lesson.title;
  const videoId = lesson.videoId;
  const description = lesson.description;
  // @ts-ignore
  const lessonExercise = lesson.exercises || [];

  const [exercise, setExercise] = useState(null);
  const [correctAnswers, setCorrectAnswer] = useState<string[]>([]);
  const audioCorrectRef = useRef<HTMLAudioElement>();

  // Start Exercise On Page Load
  useEffect(() => {
    // If exercise exist load it, else create new one
    (async function () {
      const exercise = await getExerciseById({ userId, lessonId });
      setExercise(exercise);
      exercise.correctAnswers && setCorrectAnswer(exercise.correctAnswers);
    })();

    if (!exercise) {
      (async function () {
        const exercise = await startExerciseAPI({
          lessonId,
          userId,
        });

        setExercise(exercise);
      })();
    }
  }, []);

  const handleStartOverExercise = async () => {
    // Start over an exercise
    await persistCorrectAnswer({
      correctAnswers: [],
      exerciseId: exercise?.id,
    });

    setCorrectAnswer([]);
  };

  const handleCorrectAnswer = (questionId: string) => {
    if (!exercise && !exercise.id) return;

    setCorrectAnswer([...correctAnswers, questionId]);
    persistCorrectAnswer({
      correctAnswers: [...correctAnswers, questionId],
      exerciseId: exercise?.id,
    });
  };

  const isComplete = lessonExercise.length === correctAnswers.length;
  useEffect(() => {
    if (!exercise) return;

    if (isComplete) {
      persistExerciseCompleted({ exerciseId: exercise.id });
    }
  }, [isComplete]);

  return (
    <>
      <SEO title={lessonTitle} />
      <Layout>
        <BreadcrumbOne
          title={`Lesson ${lessonTitle}`}
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Lesson"
        />
        <div className="edu-course-details-area bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="main-image thumbnail">
                  <iframe
                    className="radius-small"
                    width="100%"
                    height="500px"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title=""
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>

            <div className="row g-5 mt--10">
              <div className="col-sm-12 col-lg-6">
                <h6 className="title">{description}</h6>
                <div className="read-more-btn">
                  <a href={slidersLink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-youtube"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 4.5v7l5-3.5-5-3.5z" />
                    </svg>
                    Go to Lesson Slides
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 lesson-side-panel">
                <div className="correct-answers">
                  Correct Answers: <b>{correctAnswers.length}</b>
                </div>
                <div className="read-more-btn mt--15">
                  <button
                    onClick={handleStartOverExercise}
                    className="edu-btn w-100 text-center"
                  >
                    Start Over
                  </button>
                </div>
              </div>
            </div>

            <div className="row g-5 mt--10 mb--20">
              <div className="col-lg-12">
                <div className="course-details-content">
                  <div className="course-details-card mt--40">
                    <div
                      className="course-author-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        opacity: exercise ? 1 : 0.5,
                      }}
                    >
                      {lessonExercise.map((exercise, key) => {
                        return (
                          <ExerciseItem
                            key={`exercise_key_${key}`}
                            exercise={exercise}
                            handleCorrectAnswer={handleCorrectAnswer}
                            audioCorrectRef={audioCorrectRef}
                            correctExerciseIds={correctAnswers}
                          />
                        );
                      })}
                      <div className="course-details-two-content author-content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
