import { useState, useEffect } from "react";
import { getXataClient } from "./xata";

const xata = getXataClient();

export const useGetLessons = () => {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    (async function () {
      const records = await xata.db.lessons.getAll({
        filter: {
          isArchived: false,
        },
        sort: [{ orderNumber: "asc" }],
      });
      const serializedRecords = records.toSerializable();
      setLessons(serializedRecords);
    })();
  }, []);

  return { lessons };
};
