import React from "react";
import { useAuth } from "../../hooks/auth";
import { GoogleLogin } from "@react-oauth/google";
import { verifyLogin } from "../../services/xataDbLogin";

// 1. call an api, send user/pass
// 2. receive a token
// 3. store token
// 4. each api call - send refresh token

const handleGoogleLoginError = (error) => {
  console.log(error);
};

const LoginForm = () => {
  const { login } = useAuth();

  const handleGoogleLoginSuccess = (response) => {
    login("google", response);
    console.log(response);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const email = formData.get("email");
    const password = formData.get("password");

    const user = await verifyLogin({ email, password });
    console.log(email);
    console.log(password);
    console.log(user);

    if (user) {
      login("app", user);
    } else {
      alert("Login failed.");
    }
  };

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="login-form-box">
          <h3 className="mb-30">Login</h3>
          <form className="login-form" action="#" onSubmit={handleFormSubmit}>
            <div className="input-box mb--30">
              <input type="text" name="email" placeholder="Username or Email" />
            </div>
            <div className="input-box mb--30">
              <input type="password" name="password" placeholder="Password" />
            </div>
            <div className="comment-form-consent input-box mb--30">
              <input id="checkbox-1" type="checkbox" />
              <label htmlFor="checkbox-1">Remember Me</label>
            </div>
            <button className="rn-btn edu-btn w-100 mb--30" type="submit">
              <span>Login</span>
            </button>
            {/* <div className="input-box">
          <a href="#" className="lost-password">
            Lost your password?
          </a>
        </div> */}
          </form>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mb--10">
          {/* <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
            width={"100%"}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
